<template>
    <v-btn-toggle v-model="value" dense color="primary">
        <v-btn :value="null" color="white">전체</v-btn>
        <v-btn v-for="{ text, value } in types" v-bind="{ value }" :key="value" color="white"> {{ text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
import { PRODUCT_TYPES } from "@/assets/variables";

const types = Object.values(PRODUCT_TYPES).filter(({ value }) => value != PRODUCT_TYPES.TARGET_PAYMENT.value);
export default {
    data: () => ({
        value: null,
        types,
    }),
    computed: {
        type() {
            return this.$route.query.type || null;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        type() {
            if (this.value !== this.type) this.init();
        },
        value() {
            if (this.value !== this.type) {
                this.$router.push({ query: { ...this.$route.query, type: this.value || undefined, page: 1 } });
            }
        },
    },
    methods: {
        init() {
            this.value = this.type;
        },
    },
};
</script>

<style></style>
