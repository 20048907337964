var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1160",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.isCreate ? '상품 등록' : '상품 상세'
    }
  }), _c('view-section-card', _vm._b({
    attrs: {
      "title": "카테고리"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('shop-category-select', _vm._b({
    model: {
      value: _vm.product._category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_category", $$v);
      },
      expression: "product._category"
    }
  }, 'shop-category-select', Object.assign({}, _vm.attrs_input, {
    categories: _vm.categories
  }), false))], 1)], 1), _c('v-row', {
    staticClass: "mx-n2 my-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "썸네일"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('image-input', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "flat": ""
    },
    model: {
      value: _vm.product.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.product, "thumb", $$v);
      },
      expression: "product.thumb"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('view-section-card', _vm._b({
    staticClass: "fill-height",
    attrs: {
      "title": "상품 이미지"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('images-input', {
    attrs: {
      "md": 2
    },
    model: {
      value: _vm.product.images,
      callback: function ($$v) {
        _vm.$set(_vm.product, "images", $$v);
      },
      expression: "product.images"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "상품정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "상품유형",
      "items": _vm.productTypes,
      "disabled": !_vm.isCreate
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.type,
      callback: function ($$v) {
        _vm.$set(_vm.product, "type", $$v);
      },
      expression: "product.type"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상품명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상품코드"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.code,
      callback: function ($$v) {
        _vm.$set(_vm.product, "code", $$v);
      },
      expression: "product.code"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연관상품코드",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.relatedGroupCode,
      callback: function ($$v) {
        _vm.$set(_vm.product, "relatedGroupCode", $$v);
      },
      expression: "product.relatedGroupCode"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "판매가 (0원 일때 '무료'로 문구변경)",
      "suffix": "원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "재고",
      "disabled": _vm.product.optionsEnabled
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.stock,
      callback: function ($$v) {
        _vm.$set(_vm.product, "stock", $$v);
      },
      expression: "product.stock"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사이즈",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.size,
      callback: function ($$v) {
        _vm.$set(_vm.product, "size", $$v);
      },
      expression: "product.size"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우선순서",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.index,
      callback: function ($$v) {
        _vm.$set(_vm.product, "index", $$v);
      },
      expression: "product.index"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "진열순서"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sequence", $$v);
      },
      expression: "product.sequence"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "브랜드",
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product._brand,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_brand", $$v);
      },
      expression: "product._brand"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제조사"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.manufacturer,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manufacturer", $$v);
      },
      expression: "product.manufacturer"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "원산지"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.origin,
      callback: function ($$v) {
        _vm.$set(_vm.product, "origin", $$v);
      },
      expression: "product.origin"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-combobox', _vm._b({
    attrs: {
      "label": "플래그",
      "multiple": "",
      "dense": false,
      "append-icon": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.product.flags,
      callback: function ($$v) {
        _vm.$set(_vm.product, "flags", $$v);
      },
      expression: "product.flags"
    }
  }, 'v-combobox', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('shop-product-hashtags', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-section-title', {
    attrs: {
      "title": "전시여부"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.product.shows,
            callback: function ($$v) {
              _vm.$set(_vm.product, "shows", $$v);
            },
            expression: "product.shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-section-title', {
    attrs: {
      "title": "판매여부"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.product.sells,
            callback: function ($$v) {
              _vm.$set(_vm.product, "sells", $$v);
            },
            expression: "product.sells"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-link', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-link', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.displayCodes, function (item) {
    return [_c('v-col', {
      key: `${item.value}-item`,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('view-section-title', {
      attrs: {
        "title": item.text
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function () {
          return [_c('shop-product-groups', _vm._b({
            attrs: {
              "code": item.value
            },
            on: {
              "input": _vm.emit
            },
            model: {
              value: _vm.product.groups,
              callback: function ($$v) {
                _vm.$set(_vm.product, "groups", $$v);
              },
              expression: "product.groups"
            }
          }, 'shop-product-groups', _vm.attrs_switch, false))];
        },
        proxy: true
      }], null, true)
    }), _c('v-divider', {
      staticStyle: {
        "margin-bottom": "-1px"
      }
    })], 1)];
  })], 2)], 1)], 1), _vm.productLabels.length ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.productLabels, function (item) {
    return [_c('v-col', {
      key: `${item.value}-item`,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('view-section-title', {
      attrs: {
        "title": item.text
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function () {
          return [_c('shop-product-labels', _vm._b({
            attrs: {
              "code": item.value
            },
            on: {
              "input": _vm.emit
            },
            model: {
              value: _vm.product.labels,
              callback: function ($$v) {
                _vm.$set(_vm.product, "labels", $$v);
              },
              expression: "product.labels"
            }
          }, 'shop-product-labels', _vm.attrs_switch, false))];
        },
        proxy: true
      }], null, true)
    }), _c('v-divider', {
      staticStyle: {
        "margin-bottom": "-1px"
      }
    })], 1)];
  })], 2)], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-discount', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-discount', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-youtube', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-youtube', {
    loading: _vm.loading
  }, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.product.type != _vm.PRODUCT_TYPES.PRODUCT_BUNDLE.value,
      expression: "product.type != PRODUCT_TYPES.PRODUCT_BUNDLE.value"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('shop-product-variation', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-variation', {
    loading: _vm.loading
  }, false))], 1)])], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBundle,
      expression: "isBundle"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('shop-product-bundleds', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-bundleds', {
    loading: _vm.loading
  }, false))], 1)])], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-choicees', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-choicees', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-relateds', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-relateds', {
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-seo', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-seo', {
    loading: _vm.loading
  }, false))], 1), !_vm.isCreate ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-product-shipping', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'shop-product-shipping', {
    loading: _vm.loading
  }, false))], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "상품 상세설명"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [!_vm.loading ? _c('naver-smarteditor', {
    staticClass: "mb-n2",
    attrs: {
      "rows": "10",
      "bSkipXssFilter": "",
      "usesCustomImageUploader": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.product.content,
      callback: function ($$v) {
        _vm.$set(_vm.product, "content", $$v);
      },
      expression: "product.content"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-btn', _vm._b({
    staticClass: "mb-16",
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "white",
      "elevation": "1",
      "href": `/shop/products/${(_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._id}`,
      "target": "_blank"
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-link-variant")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }