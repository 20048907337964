var render = function render(){
  var _vm$summary$totalCoun, _vm$summary$totalCoun2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "상품 목록",
      "showsFilterButton": ""
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('product-labels-list-form', {
          on: {
            "input": _vm.getSetting__console
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "height": "32",
                  "width": "32",
                  "x-small": "",
                  "fab": "",
                  "color": "secondary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-cog ")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('product-list-search', _vm._b({}, 'product-list-search', {
    loading: _vm.loading,
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mt-n3"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "caption v-sheet--outlined",
    staticStyle: {
      "background-color": "white"
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-layout', {
    attrs: {
      "align-baseline": ""
    }
  }, [_c('span', [_vm._v(" 검색결과: ")]), _c('b', {
    staticClass: "mx-1"
  }, [_vm._v(" " + _vm._s((_vm$summary$totalCoun = _vm.summary.totalCount) === null || _vm$summary$totalCoun === void 0 ? void 0 : (_vm$summary$totalCoun2 = _vm$summary$totalCoun.format) === null || _vm$summary$totalCoun2 === void 0 ? void 0 : _vm$summary$totalCoun2.call(_vm$summary$totalCoun)) + " ")]), _vm._v(" 건 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('product-list-types')], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "caption v-sheet--outlined",
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "color": "green--text"
    },
    on: {
      "click": _vm.excel
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('span', [_c('v-icon', [_vm._v("mdi-microsoft-excel")]), _vm._v(" 엑셀다운로드 ")], 1)])], 1)], 1), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [header.withCopy ? [_c('v-tooltip', {
            key: index,
            attrs: {
              "bottom": "",
              "content-class": "text-caption"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref3) {
                var on = _ref3.on,
                  attrs = _ref3.attrs;
                return [_c('v-sheet', _vm._g(_vm._b({
                  staticClass: "text-truncate cursor-pointer",
                  attrs: {
                    "color": "transparent"
                  },
                  on: {
                    "click": function ($event) {
                      _vm.copy(header.formatter.bind(item)(value));
                    }
                  }
                }, 'v-sheet', attrs, false), on), [_c('span', {
                  staticClass: "text-truncate",
                  domProps: {
                    "innerHTML": _vm._s(header.formatter.bind(item)(value))
                  }
                })])];
              }
            }], null, true)
          }, [_vm._v(" 클릭하시면 복사됩니다. ")])] : [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })]];
        }
      };
    }), {
      key: `item.goods`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('item-product', {
          staticClass: "mx-n2",
          attrs: {
            "product": item,
            "isLink": ""
          }
        })];
      }
    }, {
      key: `item.POPULAR`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('shop-product-groups', _vm._b({
          attrs: {
            "code": _vm.DISPLAY_CODES.POPULAR.value
          },
          on: {
            "input": function (groups) {
              return _vm.update({
                _id: item._id,
                groups
              });
            }
          },
          model: {
            value: item.groups,
            callback: function ($$v) {
              _vm.$set(item, "groups", $$v);
            },
            expression: "item.groups"
          }
        }, 'shop-product-groups', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.BEST_SELLER`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('shop-product-groups', _vm._b({
          attrs: {
            "code": _vm.DISPLAY_CODES.BEST_SELLER.value
          },
          on: {
            "input": function (groups) {
              return _vm.update({
                _id: item._id,
                groups
              });
            }
          },
          model: {
            value: item.groups,
            callback: function ($$v) {
              _vm.$set(item, "groups", $$v);
            },
            expression: "item.groups"
          }
        }, 'shop-product-groups', _vm.attrs_switch, false))];
      }
    }, _vm._l(_vm.productLabels, function (label) {
      return {
        key: `item.labels.${label.value}`,
        fn: function (_ref7) {
          var item = _ref7.item;
          return [_c('shop-product-groups', _vm._b({
            key: label.value,
            attrs: {
              "code": label.value
            },
            on: {
              "input": function (labels) {
                return _vm.update({
                  _id: item._id,
                  labels
                });
              }
            },
            model: {
              value: item.labels,
              callback: function ($$v) {
                _vm.$set(item, "labels", $$v);
              },
              expression: "item.labels"
            }
          }, 'shop-product-groups', _vm.attrs_switch, false))];
        }
      };
    }), {
      key: `item.sells`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (sells) {
              return _vm.update({
                _id: item._id,
                sells
              });
            }
          },
          model: {
            value: item.sells,
            callback: function ($$v) {
              _vm.$set(item, "sells", $$v);
            },
            expression: "item.sells"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (shows) {
              return _vm.update({
                _id: item._id,
                shows
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "to": `${_vm.$route.path}/${item._id}`
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "to": `${_vm.$route.path}/${item._id}?mode=copy`
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "복사"
          }
        }, [_vm._v("mdi-content-copy")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "삭제"
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": "",
      "to": `${_vm.$route.path}/create`
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-snackbar', {
    staticStyle: {
      "opacity": "0.85"
    },
    attrs: {
      "timeout": "1000",
      "color": "primary",
      "content-class": "text-center font-weight-bold"
    },
    model: {
      value: _vm.showsCopied,
      callback: function ($$v) {
        _vm.showsCopied = $$v;
      },
      expression: "showsCopied"
    }
  }, [_vm._v(" 복사되었습니다. ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }