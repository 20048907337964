<template>
    <v-select v-model="active" v-bind="{ ...$attrs, label, items }" @change="emit" />
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => {} },
        label: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        active: null,
    }),
    computed: {
        items() {
            return [
                { text: "전체", value: null },
                { text: "활성", value: true },
                { text: "비활성", value: false },
            ];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            if (this.value?.$in?.includes?.(this.code)) this.active = true;
            else if (this.value?.$nin?.includes?.(this.code)) this.active = false;
            else this.active = null;
        },
        emit() {
            let { ...labels } = this.value;

            switch (this.active) {
                case true: {
                    if (!labels?.$in?.includes?.(this.code)) {
                        labels.$in = [...(labels?.$in || [])];
                        labels.$in.push(this.code);
                    }
                    if (labels?.$nin?.includes?.(this.code)) {
                        labels.$nin = [...(labels?.$nin || [])];
                        labels.$nin.splice(labels.$nin.indexOf(this.code), 1);
                    }
                    break;
                }

                case false: {
                    if (labels?.$in?.includes?.(this.code)) {
                        labels.$in = [...(labels?.$in || [])];
                        labels.$in.splice(labels.$in.indexOf(this.code), 1);
                    }
                    if (!labels?.$nin?.includes?.(this.code)) {
                        labels.$nin = [...(labels?.$nin || [])];
                        labels.$nin.push(this.code);
                    }
                    break;
                }

                case null: {
                    if (labels?.$in?.includes?.(this.code)) {
                        labels.$in = [...(labels?.$in || [])];
                        labels.$in.splice(labels.$in.indexOf(this.code), 1);
                    }
                    if (labels?.$nin?.includes?.(this.code)) {
                        labels.$nin = [...(labels?.$nin || [])];
                        labels.$nin.splice(labels.$nin.indexOf(this.code), 1);
                    }
                    break;
                }
            }

            this.$emit("input", labels);
        },
    },
};
</script>

<style>
</style>