var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    ref: "data-table",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1,
      "mobile-breakpoint": "610"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "sort": !_vm.loading || _vm.items.length < 2
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.handle`,
      fn: function () {
        return [_c('v-btn', {
          staticStyle: {
            "cursor": "move"
          },
          attrs: {
            "text": "",
            "tile": "",
            "color": "grey darken-1",
            "height": "48",
            "disabled": _vm.items.length < 2
          }
        }, [_c('v-icon', [_vm._v("mdi-drag")])], 1)];
      },
      proxy: true
    }, {
      key: `item.text`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field', _vm._b({
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.text,
            callback: function ($$v) {
              _vm.$set(item, "text", $$v);
            },
            expression: "item.text"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.value`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-text-field', _vm._b({
          attrs: {
            "disabled": item.disabled
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.value,
            callback: function ($$v) {
              _vm.$set(item, "value", $$v);
            },
            expression: "item.value"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.color`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-combobox', _vm._b({
          attrs: {
            "items": _vm.colors,
            "label": "HEXA",
            "clearable": ""
          },
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function () {
              return [_c('product-labels-list-form-color', {
                on: {
                  "input": _vm.emit
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function (_ref5) {
                    var attrs = _ref5.attrs,
                      on = _ref5.on;
                    return [_c('v-card', _vm._g(_vm._b({
                      staticClass: "ma-0",
                      attrs: {
                        "flat": "",
                        "outlined": "",
                        "width": "24",
                        "height": "24",
                        "rounded": "xl",
                        "color": item.color
                      }
                    }, 'v-card', attrs, false), on))];
                  }
                }], null, true),
                model: {
                  value: item.color,
                  callback: function ($$v) {
                    _vm.$set(item, "color", $$v);
                  },
                  expression: "item.color"
                }
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: item.color,
            callback: function ($$v) {
              _vm.$set(item, "color", $$v);
            },
            expression: "item.color"
          }
        }, 'v-combobox', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-switch', _vm._b({
          attrs: {
            "disabled": item.disabled
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item,
          index = _ref7.index;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "tile": "",
            "height": "48",
            "color": "red",
            "disabled": item.disabled
          },
          on: {
            "click": function ($event) {
              return _vm.pull(index);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          attrs: {
            "no-gutters": "",
            "align": "center"
          }
        }, [_c('v-spacer'), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "tile": "",
            "height": "58",
            "color": "primary"
          },
          on: {
            "click": _vm.push
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }