<template>
    <v-dialog v-model="shows" width="720" :persistent="loading">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card outlined>
            <v-card-title>상품 레이블 설정</v-card-title>
            <v-divider />
            <product-labels-list-form-table v-model="form.shop.productLabels" />
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn text color="red" @click="shows = false">취소하기</v-btn>
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapActions, mapState } from "vuex";

import { attrs_input, attrs_switch } from "@/assets/variables/attrs";
import { initSetting } from "@/assets/variables/inits";
import ProductLabelsListFormTable from "./product-labels-list-form-table.vue";

export default {
    components: {
        ProductLabelsListFormTable,
    },
    props: {
        value: { type: Object, default: initSetting },
    },
    data: () => ({
        form: initSetting(),

        attrs_input,
        attrs_switch,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),

        async sync() {
            await this.getSetting__console();
            this.form = initSetting(this.setting);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                const { shop } = this.form;
                const { setting } = await api.console.setting.put({ shop });
                this.shows = false;
                this.$emit("input", setting);
                await this.getSetting__console();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>