<template>
    <v-switch v-model="active" v-bind="$attrs" @change="emit" />
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // labels
        code: { type: String, default: null },
    },
    data: () => ({
        active: false,
        labels: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.labels = [...this.value];
            this.active = this.value.includes(this.code);
        },
        emit() {
            let items = [...this.value];

            if (this.active) items.push(this.code);
            else items = items.filter((item) => item != this.code);
            this.$emit("input", items);
        },
    },
};
</script>

<style>
</style>